import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

import alain_couture from '../images/collaborations/alain_couture.jpg';
import alan_baculis from '../images/collaborations/alan_baculis.jpg';
import ange_curcio from '../images/collaborations/ange-e_curcio.jpg';
import benett_corboz from '../images/collaborations/benett_corboz.jpg';
import bernard_padmaran from '../images/collaborations/bernard_padmaran.jpg';
import claude_arseneault from '../images/collaborations/claude_arseneault.jpg';
import claude_senecal from '../images/collaborations/claude_senecal.jpg';
import danny_ranallo from '../images/collaborations/danny_ranallo.jpg';
import jacques_gaines from '../images/collaborations/jacques_gaines.jpg';
import jean_nadeau from '../images/collaborations/jean_nadeau.jpg';
import jeansebastien_carre from '../images/collaborations/jean-sebastien_carre.jpg';
import john_mcgale from '../images/collaborations/john_mcgale.jpg';
import kim_richardson from '../images/collaborations/kim_richardson.jpg';
import lamiryan from '../images/collaborations/lamiryan.jpg';
import mario_lessard from '../images/collaborations/mario_lessard.jpg';
import maurice_gervais from '../images/collaborations/maurice_gervais.jpg';
import rick_hughes from '../images/collaborations/rick_hughes.jpg';
import roger_tabra from '../images/collaborations/roger_tabra.jpg';
import sandrine_roy from '../images/collaborations/sandrine_roy.jpg';
import sylvain_michel from '../images/collaborations/sylvain_michel.jpg';
import vincenzo_thoma from '../images/collaborations/vincenzo_thoma.jpg';
import yves_frulla from '../images/collaborations/yves_frulla.jpg';

const CollaborationsPage = () => (
  <Layout>
    <SEO title="Collaborations" />
    <Helmet bodyAttributes={{ class: 'collaborations' }} />
    <div className="staticContent" id="staticContent_collaborations">
      <h1>Collaborations</h1>
      <p>
        Un coup de pouce du destin lui a permis de rencontrer l'un des plus
        grands auteurs francophones, Monsieur Roger Tabra. Cet homme au grand
        c&#339;ur, reconnaissant le talent de Leelo Pascal, lui a fait l'honneur
        et l'amiti&#233; d'&#233;crire cinq textes qui lui ressemblent.
      </p>
      <p>&#160;</p>
      <p>
        Leelo Pascal d&#233;bute la r&#233;alisation de son album avec comme
        arrangeur Yves Frulla clavi&#233;riste de C&#233;line Dion qui lui donne
        sa touche magique au piano, Hammond B-3 et clavier. Une belle alchimie
        musicale est n&#233;e de cette rencontre profonde et artistique. Tour
        &#224; tour, de nombreux auteurs, compositeurs et musiciens, ayant
        accompagn&#233; les plus grands chanteurs actuels, ont mis leur
        exp&#233;rience musicale avec brio au service de son album
        &#171;&#160;Couleur Nature&#160;&#187;. De la destin&#233;e unique de
        Leelo Pascal s'est cr&#233;&#233;e une &#233;quipe dont la force et le
        talent n'est plus &#224; d&#233;montrer.
      </p>
      <p>&#160;</p>
      <p>
        Sous ses directives et en &#233;troite collaboration avec chacun, Leelo
        Pascal a trouv&#233; la magie des sons et des couleurs Pop-Rock qu'il
        d&#233;sirait pour son album.
      </p>
      <p>&#160;</p>
      <p>
        Plus qu'une &#233;quipe, ce sont des Amis qui entourent maintenant cet
        artiste. Une belle &#233;nergie et un r&#233;el respect lie tous les
        acteurs de cette cr&#233;ation. Convaincus par le talent de Leelo
        Pascal, tous se sont donn&#233; c&#339;ur et &#226;me pour entrer dans
        sa vision artistique, et se r&#233;jouissent de l'accompagner sur
        sc&#232;ne&#160;!
      </p>
      <div className="collabBlock">
        <ul className="collabBig group">
          <li>
            <h2>Roger Tabra</h2>
            <span className="imgFrame">
              <img
                alt="Roger Tabra"
                height="120"
                src={roger_tabra}
                width="120"
              />
            </span>{' '}
            <span className="position">Parolier</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Le plus grand parolier de la derni&#232;re d&#233;cennie au
              Qu&#233;bec, l'auteur ch&#233;ri &#224; grands succ&#232;s!
              Isabelle Boulay, <em>Johnny Hallyday</em>, Garou, Bruno Pelletier,
              Rock Voisine, &#201;ric Lapointe, Marie-Chantal Toupin, Laurence
              Jalbert, Adam Cohen, etc. Entre Roger Tabra &amp; Leelo Pascal,
              c'est une rencontre d'&#226;me et un destin!
            </p>
          </li>
          <li>
            <h2>Yves Frulla</h2>
            <span className="imgFrame">
              <img
                alt="Roger Tabra"
                height="120"
                src={yves_frulla}
                width="120"
              />
            </span>
            <span className="position">
              Arrangeur de l'album de Leelo Pascal, Compositeur, Directeur
              artistique live
            </span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Clavi&#233;riste officiel de CELINE DION depuis plus de 18 ans,
              Yves Frulla a accompagn&#233; de nombreux artistes comme FLORENT
              PAGNY, GAROU, SYLVAIN COSSETTE, COREY HART, CLAUDE DUBOIS
              Compositeur, il &#233;crit notamment du th&#232;me des OLYMPIQUES
              DE SALT LAKE CITY.
            </p>
          </li>
        </ul>
        <dl className="collabSmall group">
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Claude Senecal"
                height="70"
                src={claude_senecal}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Claude Senecal</h2>
            <span className="position">Compositeur, Guitariste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Compositeur et chef dorchestre pour de nombreux artistes et
              musiques de films, il gagne de nombreux prix dont le Disque de
              platine&#160;pour MARIE-CHANTAL TOUPIN en 2004&#160;; prix
              F&#233;lix de lAdisq&#160; 2005&#160;; prix Socan en 2005 et 2006.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Vincenzo Thoma"
                height="70"
                src={vincenzo_thoma}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Vincenzo Thoma</h2>
            <span className="position">Compositeur</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>LARA FABIAN, ROCK VOISINE, PATRICK FIORI, CHIMENE BADI</p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Sylvain Michel"
                height="70"
                src={sylvain_michel}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Sylvain Michel</h2>
            <span className="position">Compositeur</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              SERGE LAMA, ISABELLE BOULAY, BRUNO PELLETIER, DIANE DUFRESNE,
              FLORENT VOLENT
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Ange-e. Curcio"
                height="70"
                src={ange_curcio}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Ange-e. Curcio</h2>
            <span className="position">Batteur</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Ange-E a partag&#233; la sc&#232;ne avec de nombreux artistes tels
              que TONY LEVIN, COREY HART, ERIC LAPOINTE, BON JOVI, FLORENT
              PAGNY, MELANIE RENAUD, KEVIN PARENT, LAURENCE JALBERT, NANETT
              WORKMAN, GAROU
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Alan Baculis"
                height="70"
                src={alan_baculis}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Alan Baculis</h2>
            <span className="position">Bassiste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              ELVIS COSTELLO, JOAN JETT, LEMMY (MOTHERHEAD), LARA FABIAN, COREY
              HART, PATRICK FIORI
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Kim Richardson"
                height="70"
                src={kim_richardson}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Kim Richardson</h2>
            <span className="position">Choriste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Kim accompagne les plus grands chanteurs tels que: BARRY WHITE,
              STEVIE WONDER, ROBERT PALMER, PAUL ANKA, ANGGUN, MAURANE, CHRIS DE
              BURGH, TINA ARENA, CELINE DION, ISABELLE BOULAY, GAROU, NANETTE
              WORKMAN, LUCE DUFAULT, et tant dautres dont LEELO PASCAL auquel
              elle a fait lhonneur de pr&#234;ter son incroyable talent vocal.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Rick Hughes"
                height="70"
                src={rick_hughes}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Rick Hughes</h2>
            <span className="position">Choriste principal</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Outre sa carri&#232;re personnelle &#224; succ&#232;s, Rick
              accompagne sur sc&#232;ne divers artistes tels que ALICE COOPER,
              ERIC LAPOINTE ET JON BON JOVI.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Alain Couture"
                height="70"
                src={alain_couture}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Alain Couture</h2>
            <span className="position">Choriste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Choriste de carri&#232;re pour JOH NNY HALLYDAY, Rock Voisine,
              Garou, C&#233;line Dion
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Sandrine Roy"
                height="70"
                src={sandrine_roy}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Sandrine Roy</h2>
            <span className="position">Paroli&#232;re</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>GAROU, BRUNO PELLETIER</p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Jean Nadeau"
                height="70"
                src={jean_nadeau}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Jean Nadeau</h2>
            <span className="position">Batterie, Percussions</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Diane Dufresne, Nicolas Peyrac, Julien Clerc, Mario Pelchat,
              Murray Head
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Claude Arseneault"
                height="70"
                src={claude_arseneault}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Claude Arseneault</h2>
            <span className="position">Bassiste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Charles Aznavour, Nicole Croisille, Diane Dufresne, Robert
              Charlebois...
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="John McGale"
                height="70"
                src={john_mcgale}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>John McGale</h2>
            <span className="position">Guitariste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Il a notamment accompagn&#233; sur sc&#232;ne&#160;: CELINE DION,
              NATASHA ST-PIER, JOE COCKER, OFFENBACH, B.B. KING, CHUCK BERRY
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Danny Ranallo"
                height="70"
                src={danny_ranallo}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Danny Ranallo</h2>
            <span className="position">Guitariste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              GAROU, LARA FABIAN, ROCK VOISINE, BRUNO PELLETIER, COREY HART.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Mario Lessard"
                height="70"
                src={mario_lessard}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Mario Lessard</h2>
            <span className="position">Guitariste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              En sessions et en tourn&#233;e avec MARIE-CHANTAL TOUPIN au
              Qu&#233;bec depuis de nombreuses ann&#233;es.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Maurice Gervais"
                height="70"
                src={maurice_gervais}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Maurice Gervais</h2>
            <span className="position">Compositeur</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>Sandrine Roy, LEELO PASCAL</p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Jacques Gaines"
                height="70"
                src={jacques_gaines}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Jacques Gaines</h2>
            <span className="position">Co-Compositeur</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Auteur-compositeur interpr&#232;te, il compose un titre pour Leelo
              Pascal avec Yves Frulla.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Jean-Sebastien Carre"
                height="70"
                src={jeansebastien_carre}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Jean-Sebastien Carre</h2>
            <span className="position">Co-compositeur, Choriste</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Choriste, guitariste acoustique et violoniste de C&#233;line Dion.
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Benett Corboz"
                height="70"
                src={benett_corboz}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Benett Corboz</h2>
            <span className="position">Compositeur</span>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Composition de plusieurs com&#233;dies musicales produites en
              Europe
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Lamiryan"
                height="70"
                src={lamiryan}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Lamiryan</h2>
            <h3>R&#233;f&#233;rences Musicales</h3>
            <p>
              Assistante de Cible Music International; collaboratrice directe de
              LEELO PASCAL et son &#233;quipe, Lamiryan a co-&#233;crit 3 titres
              avec Leelo Pascal pour son album "Couleur Nature"
            </p>
          </dd>{' '}
          <dt>
            {' '}
            <span className="imgFrame">
              <img
                alt="Bernard Padmaran"
                height="70"
                src={bernard_padmaran}
                width="70"
              />
            </span>{' '}
          </dt>{' '}
          <dd>
            <h2>Bernard Padmaran</h2>
            <h3>Vid&#233;oclip "Mais que restera-t-il"</h3>
            <p>
              Production&#160;: Padmaram /Cible Music International;
              R&#233;alisation: Angelo Dello Iacono/ Leelo Pascal; Montage et
              postproduction: Pierre-Yves Detrey; Prises de vues et cadre :
              Sebastien Goyon et Paolo Rudelli; &#169; 2010
            </p>
          </dd>{' '}
        </dl>
      </div>
    </div>
  </Layout>
);

export default CollaborationsPage;
